import React, { useState } from "react"
import Layout from "../components/common/layout"
import Accordion from "../components/faq/accordion"
import Seo from "../components/common/seo"
import { graphql } from "gatsby"

const App = ({ data }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <Layout>
      <Seo title="Frequently Asked Questions" />
      <div className="faqPage">
        <h1> Frequently Asked Questions</h1>
        <div className="accordion">
          {data.allContentfulFaQs.nodes.map(({ id, title, content }) => (
            <Accordion title={title} content={content} key={id} />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default App

export const query = graphql`
  query FAQs($language: String) {
    allContentfulFaQs(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        title
        content {
          content
        }
      }
    }
  }
`
